import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  Country,
  Port,
  Zone,
} from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getCountryCodeKR } from "@sellernote/_shared/src/utils/common/country";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import {
  getCustomsPartnerName,
  getShipmentServiceTypeKr,
} from "@sellernote/_shared/src/utils/forwarding/adminBid";
import { getAdminBidPortName } from "@sellernote/_shared/src/utils/forwarding/bid";
import {
  changeInlandTypeToNameKr,
  changeTransPortTypeToKr,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../jotaiStates/auth";
import UpdateShipmentInfoModal from "./UpdateShipmentInfoModal";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "flex-start",
  gap: "4px",
};

const boxLabelStyle: SxProps<Theme> = {
  flex: "0 0 auto",
};

// TODO: 수출 MVP 배포 후 의뢰 상세 인터페이스 작업 시 MUI로 리팩토링
const TransportationInfo = ({
  bidDetail,
  portData,
  zoneData,
  countryList,
}: {
  bidDetail: AdminBidDetail;
  portData: Port[];
  zoneData: Zone[];
  countryList: Country[];
}) => {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [showsUpdateShipmentInfoModal, setShowsShipmentInfoUpdateModalClose] =
    useState(false);

  const isExport = !bidDetail.isImport;

  const changeCheckpointValueToKr = useCallback((value: boolean | null) => {
    switch (value) {
      case true:
        return "포함";
      case false:
        return "미포함";
      default:
        return "-";
    }
  }, []);

  const changeContainerAccessableValueToKr = useCallback(
    (value: boolean | null) => {
      switch (value) {
        case true:
          return "가능";
        case false:
          return "불가능";
        default:
          return "-";
      }
    },
    []
  );

  const StartingPointDescriptionsItem = useMemo(() => {
    if (bidDetail.startType === "inland") {
      return (
        <>
          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>출발지 경유지:</Typography>
            <Typography>{bidDetail.startViaPort?.nameEN}</Typography>
          </Box>

          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>출발지 내륙:</Typography>
            <Typography>
              {getAdminBidPortName(bidDetail, "startPort")}
            </Typography>
          </Box>

          {isExport && (
            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>출발지 상세주소:</Typography>
              <Typography>{bidDetail.startAddressDetail}</Typography>
            </Box>
          )}
        </>
      );
    }
    return (
      <Box sx={boxLayoutStyle}>
        <Typography>출발지 항구/공항:</Typography>
        <Typography>{getAdminBidPortName(bidDetail, "startPort")}</Typography>
      </Box>
    );
  }, [bidDetail, isExport]);

  const DestinationDescription = useMemo(() => {
    if (bidDetail.endType === "inland") {
      return (
        <>
          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>도착 경유지 항구:</Typography>
            <Typography>{bidDetail.endViaPort?.nameEN}</Typography>
          </Box>

          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>도착지 주소:</Typography>
            <Typography>{getAdminBidPortName(bidDetail, "endPort")}</Typography>
          </Box>

          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>도착지 상세주소:</Typography>
            <Typography>{bidDetail.endAddressDetail}</Typography>
          </Box>
        </>
      );
    }

    return (
      <Box sx={boxLayoutStyle}>
        <Typography sx={boxLabelStyle}>도착지 항구/공항:</Typography>
        <Typography>{getAdminBidPortName(bidDetail, "endPort")}</Typography>
      </Box>
    );
  }, [bidDetail]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">운송정보</Typography>

        {APP_NAME === "shipda-admin" && (
          <Button
            variant="contained"
            onClick={() => setShowsShipmentInfoUpdateModalClose(true)}
          >
            수정
          </Button>
        )}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
          rowGap: "4px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>유형:</Typography>

          <Typography>
            {getShipmentServiceTypeKr({
              serviceType: bidDetail.serviceType,
              isExpress: bidDetail.isExpress,
              isImport: bidDetail.isImport,
            })}
          </Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>국가:</Typography>

          <Box sx={{ flex: 1 }}>
            <Typography>{getCountryCodeKR(bidDetail.startCountry)}</Typography>
          </Box>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>인코텀즈:</Typography>

          <Typography>{bidDetail.incoterms}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
          rowGap: "4px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>출발지 유형:</Typography>

          <Box sx={{ flex: 1 }}>
            <Typography>
              {changeTransPortTypeToKr(bidDetail.startType)}
            </Typography>
          </Box>
        </Box>

        {StartingPointDescriptionsItem}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
          rowGap: "4px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>도착지 유형:</Typography>

          <Box>
            <Typography>
              {changeTransPortTypeToKr(bidDetail.endType)}
            </Typography>
          </Box>
        </Box>

        {DestinationDescription}
      </Box>

      <Divider sx={{ mt: 1, mb: 1 }} />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
          rowGap: "4px",
        }}
      >
        {!bidDetail.isImport && (
          <>
            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>수출통관:</Typography>

              <Box>
                <Typography>
                  {changeCheckpointValueToKr(bidDetail.containExportCustoms)}
                </Typography>
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>적하보험:</Typography>

              <Box>
                <Typography>
                  {changeCheckpointValueToKr(bidDetail.hopeCargoInsurance)}
                </Typography>
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>VGM:</Typography>

              <Box>
                {bidDetail.useVGM ? (
                  <Typography>포함</Typography>
                ) : bidDetail.freightType === "FCL" ? (
                  <Typography>불포함</Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>수입통관:</Typography>

              <Box>
                <Typography>
                  {changeCheckpointValueToKr(bidDetail.containCustoms)}
                </Typography>
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>
                출발지 컨테이너 진입가능:
              </Typography>

              <Box>
                <Typography>
                  {changeBooleanValueToKr(bidDetail.containerStuffing)}
                </Typography>
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>운송방식:</Typography>

              <Box>
                <Typography>
                  {changeInlandTypeToNameKr(
                    isExport ? bidDetail.originInland : bidDetail.inlandType
                  )}
                </Typography>
              </Box>
            </Box>
          </>
        )}

        {bidDetail.isImport && (
          <>
            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>국내부대비용 포함:</Typography>

              <Box>
                <Typography>
                  {changeCheckpointValueToKr(bidDetail.containDomesticFee)}
                </Typography>
              </Box>
            </Box>

            {bidDetail.freightType !== "AIR" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>
                  OCEAN SURCHARGE 포함:
                </Typography>

                <Box>
                  <Typography>
                    {changeCheckpointValueToKr(bidDetail.containOceanSurcharge)}
                  </Typography>
                </Box>
              </Box>
            )}

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>LSS:</Typography>

              <Box>
                <Typography>
                  {changeCheckpointValueToKr(bidDetail.containLss)}
                </Typography>
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>적하보험:</Typography>

              <Box>
                <Typography>
                  {changeCheckpointValueToKr(bidDetail.hopeCargoInsurance)}
                </Typography>
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>통관의뢰:</Typography>
              <Box>
                <Typography>
                  {`${
                    bidDetail.containCustoms
                      ? changeCheckpointValueToKr(bidDetail.containCustoms)
                      : `미포함 (${getCustomsPartnerName(
                          bidDetail.accountPayables
                        )})`
                  }`}
                </Typography>
              </Box>
            </Box>

            {bidDetail.freightType === "FCL" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>
                  도착지 컨테이너 진입여부:
                </Typography>

                <Box>
                  <Typography>
                    {bidDetail.endType === "inland"
                      ? changeContainerAccessableValueToKr(
                          bidDetail.containerAccessable
                        )
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            )}

            {bidDetail.locale === "SG" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>IOR:</Typography>

                <Box>
                  <Typography>
                    {changeCheckpointValueToKr(bidDetail.needIORAgency)}
                  </Typography>
                </Box>
              </Box>
            )}

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>FTA C/O:</Typography>

              <Box>
                <Typography>
                  {changeCheckpointValueToKr(
                    bidDetail.needFTACertificateAgency
                  )}
                </Typography>
              </Box>
            </Box>

            {bidDetail.freightType !== "FCL" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>합차/독차:</Typography>

                <Typography>
                  {changeInlandTypeToNameKr(bidDetail.inlandType)}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                ...boxLayoutStyle,
                gridColumn: "3 / 6",
              }}
            >
              <Typography sx={boxLabelStyle}>유저 코멘트:</Typography>

              <Typography>{bidDetail.userNote}</Typography>
            </Box>
          </>
        )}
      </Box>

      <UpdateShipmentInfoModal
        showsUpdateShipmentInfoModal={showsUpdateShipmentInfoModal}
        setShowsShipmentInfoUpdateModalClose={
          setShowsShipmentInfoUpdateModalClose
        }
        portList={portData}
        shipmentDetail={bidDetail}
      />
    </Box>
  );
};

export default TransportationInfo;
